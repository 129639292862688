import { passwordRegExp, testRegExp } from './regExpUtils'
// 检验账号密码
export const passValidate = (value: any, callback: any, str?: string) => {
  if (!value) {
    callback(new Error(str || 'Please enter password.'))
  } else if (!testRegExp(passwordRegExp, value)) {
    callback(new Error('Passwords must have at least 8 characters and contain at least 1 number and symbol.'))
  } else {
    callback()
  }
}
// 检验账号密码
export const againPassValidate = (value: any, callback: any, pass: any) => {
  if (!value) {
    callback(new Error('Please enter password.'))
  } else if (value !== pass) {
    callback(new Error('Passwords do not match.'))
  } else {
    callback()
  }
}
