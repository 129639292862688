import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: 'Home' */ '@/views/Home/index.vue')
  },
  {
    path: '/search',
    name: 'Search',
    meta: { navNotShow: true },
    component: () => import(/* webpackChunkName: 'SearchInfo' */ '@/views/Search/index.vue')
  },
  {
    path: '/search/china',
    name: 'SearchChina',
    meta: {
      navNotShow: true,
      countryCode: 86, // 国家code字段
      title: 'KYB China - China Company Search | Riscern',
      meta: [{ name: 'keywords', content: 'KYB China - China Company Search | Riscern' }, { name: 'description', content: 'Riscern provide real-time access to information on Chinese companies, including shareholder information, company risk data, and records of corporate changes.' }]
    },
    component: () => import(/* webpackChunkName: 'Search' */ '@/views/Search/Single.vue')
  },
  {
    path: '/search/japan',
    name: 'SearchJapan',
    meta: {
      navNotShow: true,
      countryCode: 81, // 国家code字段
      title: 'KYB Japan - Japan Company Search | Riscern',
      meta: [{ name: 'keywords', content: 'KYB Japan - Japan Company Search | Riscern' }, { name: 'description', content: 'Riscern provides you with real-time access to registered data on Japanese companies, shareholder information, corporate officer information, financial information, as well as workplace information.' }]
    },
    component: () => import(/* webpackChunkName: 'SearchInfo' */ '@/views/Search/Single.vue')
  },
  {
    path: '/search/taiwan',
    name: 'SearchTaiwan',
    meta: {
      navNotShow: true,
      countryCode: 886, // 国家code字段
      title: 'KYB Taiwan - Taiwan Company Search | Riscern',
      meta: [{ name: 'keywords', content: 'KYB Taiwan - Taiwan Company Search | Riscern' }, { name: 'description', content: 'Riscern offer real-time access to data on Taiwanese companies, including shareholder information, corporate officer data, and subsidiary information.' }]
    },
    component: () => import(/* webpackChunkName: 'SearchInfo' */ '@/views/Search/Single.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import(/* webpackChunkName: 'Product' */ '@/views/Product/index.vue'),
    children: [{
      path: 'kyb-api',
      name: 'ProductKYBAPI',
      meta: { title: 'KYB API - Business Verification API | Riscern', meta: [{ name: 'keywords', content: 'KYB API - Business Verification API | Riscern' }, { name: 'description', content: "Riscern's KYB API integrates with official company registries in each country, making it secure and easy to use and providing you with the most authoritative data." }] },
      component: () => import(/* webpackChunkName: 'Product' */ '@/views/Product/KYBAPI.vue')
    },
    {
      path: 'kyb-portal',
      name: 'ProductKYBPortal',
      meta: { title: 'KYB - Online Instant Business Verification | Riscern', meta: [{ name: 'keywords', content: 'KYB - Online Instant Business Verification | Riscern' }, { name: 'description', content: 'One platform helping you Know Your Business (KYB) and Risk Discern (Riscern).' }] },
      component: () => import(/* webpackChunkName: 'Product' */ '@/views/Product/KYBPortal.vue')
    },
    {
      path: 'kyc-api',
      name: 'ProductKYCAPI',
      meta: { title: 'KYC API - The most dedicated KYC in China | Riscern', meta: [{ name: 'keywords', content: 'KYC API - The most dedicated KYC in China | Riscern' }, { name: 'description', content: 'Reduce your KYC and compliance risks and costs with our intelligent services.' }] },
      component: () => import(/* webpackChunkName: 'KYC' */ '@/views/Product/KYCAPI.vue')
    }]
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import(/* webpackChunkName: 'Company' */ '@/views/Company/index.vue'),
    children: [
      {
        path: 'about',
        name: 'CompanyAbout',
        component: () => import(/* webpackChunkName: 'Company' */ '@/views/Company/About.vue'),
        meta: { title: 'About Us | Riscern', meta: [{ name: 'keywords', content: 'About Us | Riscern' }, { name: 'description', content: "Riscern's mission is to provide our customers with an efficient, accurate, convenient, and safe verification process." }] }
      },
      {
        path: 'contacts',
        name: 'CompanyContacts',
        component: () => import(/* webpackChunkName: 'Company' */ '@/views/Company/Contacts.vue'),
        meta: { title: 'Contact Us | Riscern', meta: [{ name: 'keywords', content: 'Contact Us | Riscern' }, { name: 'description', content: 'Need help? Please get in touch with us for any needs, and we will reply soon.' }] }
      }
    ]
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import(/* webpackChunkName: 'Resources' */ '@/views/Company/index.vue'),
    children: [
      {
        path: 'insights',
        name: 'ResourcesInsights',
        component: () => import(/* webpackChunkName: 'Resources' */ '@/views/Resources/Insights.vue'),
        meta: { title: 'Insights | Riscern', meta: [{ name: 'keywords', content: 'Insights | Riscern' }, { name: 'description', content: "Read Riscern's latest company data verification insights, news, and industry trends." }] }
      },
      {
        path: 'insights-detail/:name',
        name: 'ResourcesInsightsDetail',
        component: () => import(/* webpackChunkName: 'Resources' */ '@/views/Resources/InsightsDetail.vue')
      },
      {
        path: 'plans',
        name: 'ResourcesPlans',
        meta: { navNotShow: true, title: 'Subscription Plans | Riscern', meta: [{ name: 'keywords', content: 'Subscription Plans | Riscern' }, { name: 'description', content: 'Reasonable plans and pricing to meet your needs.' }] },
        component: () => import(/* webpackChunkName: 'CompanyInfo' */ '@/views/Resources/Plans/index.vue')
      }
    ]
  },
  {
    path: '/company-info',
    name: 'CompanyInfo',
    meta: { navNotShow: true },
    component: () => import(/* webpackChunkName: 'CompanyInfo' */ '@/views/CompanyInfo/index.vue')
  },
  {
    path: '/information',
    name: 'Information',
    meta: { navNotShow: true },
    component: () => import(/* webpackChunkName: 'Information' */ '@/views/Information/index.vue'),
    children: [
      {
        path: 'account',
        name: 'InformationAccount',
        component: () => import(/* webpackChunkName: 'Information' */ '@/views/Information/Account/index.vue')
      },
      {
        path: 'subscriptions',
        name: 'InformationSubscriptions',
        component: () => import(/* webpackChunkName: 'Information' */ '@/views/Information/Subscriptions/index.vue')
      },
      {
        path: 'download-records',
        name: 'InformationDownloadRecords',
        component: () => import(/* webpackChunkName: 'Information' */ '@/views/Information/DownloadRecords/index.vue')
      },
      {
        path: 'viewed-records',
        name: 'InformationViewedRecords',
        component: () => import(/* webpackChunkName: 'Information' */ '@/views/Information/ViewedRecords/index.vue')
      },
      {
        path: 'save-list',
        name: 'InformationSaveList',
        component: () => import(/* webpackChunkName: 'Information' */ '@/views/Information/SaveList/index.vue')
      },
      {
        path: 'monitoring-lists',
        name: 'InformationMonitoringLists',
        component: () => import(/* webpackChunkName: 'Information' */ '@/views/Information/MonitoringLists/index.vue')
      },
      {
        path: 'receipts',
        name: 'InformationReceipts',
        component: () => import(/* webpackChunkName: 'Information' */ '@/views/Information/Receipts/index.vue')
      },
      {
        path: 'change-plan',
        name: 'InformationChangePlan',
        meta: { activeMenu: '/information/subscriptions' }, // 高亮
        component: () => import(/* webpackChunkName: 'Information' */ '@/views/Information/Subscriptions/ChangePlan/index.vue')
      }
    ]
  },
  {
    path: '/change-pass',
    name: 'ChangePass',
    meta: { navNotShow: true },
    component: () => import(/* webpackChunkName: 'ChangePass' */ '@/views/ChangePass/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to) {
    if (!to.hash) return { top: 0 }
  }
})

export default router
