import Cookies from 'js-cookie'

const tokenName = 'riscern-auth'
const getToken = () => {
  return Cookies.get(tokenName)
}
const setToken = (data: string) => {
  Cookies.set(tokenName, data)
}
const removeToken = () => {
  Cookies.remove(tokenName)
}
export {
  getToken,
  setToken,
  removeToken
}
