import { ref } from 'vue'
export const initVisible = ref<boolean>(false)
export const loginUserVisible = ref<boolean>(false)
export const createVisible = ref<boolean>(false)
export const loginGoogleVisible = ref<boolean>(false)
export const loginExistVisible = ref<boolean>(false)
export const forgotPassVisible = ref<boolean>(false)

export const isLoginStatus = ref<boolean>(false) // 是否登陆状态

export const resultEmailMap = {
  0: () => {
    initVisible.value = false
    createVisible.value = true
  }, // 新用户
  1: () => {
    initVisible.value = false
    loginUserVisible.value = true
  }, // 已注册
  2: () => { loginGoogleVisible.value = true }, // 谷歌登录
  3: () => { loginUserVisible.value = true }, // 已用谷歌注册，设置密码
  4: () => { loginExistVisible.value = true } // 点击谷歌，但是已在自己平台注册
}
