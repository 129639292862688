import request from '@/utils/axios-request'
// 测试用户绑定
export const apiTestUserBind = (data: any) => {
  return request({
    url: '/user/test-user-bind',
    method: 'post',
    data
  })
}
// 国家列表
export const apiCountryList = (params: any) => {
  return request({
    url: '/subscribe/country-list',
    method: 'get',
    params
  })
}

// 获取价格
export const apiGetPrice = (params?: any) => {
  return request({
    url: '/subscribe/get-price',
    method: 'get',
    params
  })
}
// 获取升级信息所有信息
export const apiGetUpgradeInfo = (params: any) => {
  return request({
    url: '/subscribe/get-upgrade-info',
    method: 'get',
    params
  })
}

// 获取订阅信息
export const apiSubscribe = (params: any) => {
  return request({
    url: '/subscribe/get-subscribe',
    method: 'get',
    params
  })
}
