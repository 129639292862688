import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '../router'
import { isNotShowMessageCode } from '@/utils/codeUtils'
import { getToken, removeToken } from '@/utils/auth'
import { buyDialogVisible } from '@/utils/constData'

const setHeader = () => {
  const tokenData = getToken()
  const headers = {
    Authorization: tokenData || ''
  }
  return headers
}
// axios实例
const axiosInstance = axios.create({
  // baseURL: 'http://47.96.108.154:50001'
  baseURL: 'https://mp-api.riscern.com'
})
// 请求拦截
axiosInstance.interceptors.request.use(
  config => {
    if (config.url) {
      config.headers = setHeader()
      return config
    }
    throw new axios.Cancel()
  },
  error => {
    ElMessage.error(error.message)
    return Promise.reject(error)
  }
)

// 响应拦截
axiosInstance.interceptors.response.use(
  response => {
    const requestData = response.config.data ? JSON.parse(response.config.data) : {}
    if (response.data) {
      const { code } = response.data || {}
      // vip权限不匹配
      if ([820005].includes(code)) {
        buyDialogVisible.value = true
        return Promise.reject(response.data)
      }
      // token失效
      if ([810001].includes(code)) {
        removeToken()
        window.location.href = '/'
      }
      // 公司查询无记录（This organization or person information currently does not support this query method.）
      if ([820003].includes(code)) {
        setTimeout(() => {
          router.go(-1)
        }, 2000)
      }
      if (isNotShowMessageCode.includes(code)) {
        return Promise.reject(response.data)
      }
      if (code !== 0) {
        const { _isNoShowErrorMessage } = requestData || {}
        if (!_isNoShowErrorMessage) {
          ElMessage.error(response.data.message)
        }
        return Promise.reject(response.data)
      }
      return response.data
    }
  },
  error => {
    console.error('响应拦截：', error)
    ElMessage.error(error.message)
    return Promise.reject(error)
  }
)

export default axiosInstance
