import { reactive, ref } from 'vue'
export interface companyItem {
  countryCode: number,
  entityName: string,
  entityNo?: string,
  keyNo?: string
}
export const jurisdictionList = reactive([
  {
    name: 'China',
    src: require('../assets/search/china.png'),
    code: 86,
    infoKey: 'china',
    companyCountry: 'Chinese',
    systemSrc: require('../assets/companyInfo/china.jpeg')
  },
  {
    name: 'Japan',
    src: require('../assets/search/japan.png'),
    code: 81,
    infoKey: 'japan',
    companyCountry: 'Japanese',
    systemSrc: require('../assets/companyInfo/japan.png')
  },
  {
    name: 'Taiwan',
    src: require('../assets/search/taiwan.png'),
    code: 886,
    infoKey: 'taiwan',
    companyCountry: 'Taiwan',
    systemSrc: require('../assets/companyInfo/taiwan.png')
  }
  // {
  //   name: 'Singapore',
  //   src: require('../assets/search/singapore.png'),
  //   code: 65
  // },
  // {
  //   name: 'India',
  //   src: require('../assets/search/india.png'),
  //   code: 91
  // },
  // {
  //   name: 'Hong Kong',
  //   src: require('../assets/search/hongkang.png'),
  //   code: 852
  // }
])
// pro,deep
export const DATA_TYPE = reactive({
  NORMAL: 0,
  DEEP: 1,
  PRO: 2
})

export const buyDialogVisible = ref(false)
