import request from '@/utils/axios-request'
// 下载报告列表
export const apiReportRecordList = (data: any) => {
  return request({
    url: '/report/report-record-list',
    method: 'post',
    data
  })
}
// 报告下载
export const apiDownload = (data: any) => {
  return request({
    url: '/report/download',
    method: 'post',
    data
  })
}
// 收藏列表
export const apiRecordList = (data:any) => {
  return request({
    url: '/save/record-list',
    method: 'post',
    data
  })
}
// 删除收藏
export const apiDel = (data: any) => {
  return request({
    url: '/save/del',
    method: 'post',
    data
  })
}
// 监控列表
export const apiMonitorRecordList = () => {
  return request({
    url: '/monitor/record-list',
    method: 'get'
  })
}
// 删除监控
export const apiMonitorDel = (data: any) => {
  return request({
    url: '/monitor/del',
    method: 'post',
    data
  })
}
// 重置密码
export const apiResetPassword = (data: any) => {
  return request({
    url: '/user/reset-password',
    method: 'post',
    data
  })
}
// 修改密码
export const apiModifyPassword = (data: any) => {
  return request({
    url: '/user/modify-password',
    method: 'post',
    data
  })
}
// 收据列表
export const apiReceiptList = (data: any) => {
  return request({
    url: '/subscribe/receipt-list',
    method: 'post',
    data
  })
}

// 获取用户卡信息
export const apiGetUserCard = (params: any) => {
  return request({
    url: '/subscribe/get-user-card',
    method: 'get',
    params
  })
}
// 获取用户订阅信息
export const apiGetCurrentSubscribe = (params: any) => {
  return request({
    url: '/subscribe/get-current-subscribe',
    method: 'get',
    params
  })
}
// 恢复订阅
export const apiResumeSubscribe = (params: any) => {
  return request({
    url: '/subscribe/resume-subscribe',
    method: 'get',
    params
  })
}
// 修改用户卡信息
export const apiUpdateUserCard = (data: any) => {
  return request({
    url: '/subscribe/update-user-card',
    method: 'post',
    data
  })
}
// vip用户访问列表
export const apiVipAccessList = () => {
  return request({
    url: '/search/vip-access-list',
    method: 'post'
  })
}
// 普通用户访问列表
export const apiTempAccessList = () => {
  return request({
    url: '/search/temp-access-list',
    method: 'post'
  })
}
// 升级订阅
export const apiUpgrade = (params: any) => {
  return request({
    url: '/subscribe/upgrade',
    method: 'get',
    params
  })
}
