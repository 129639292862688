const COMPANY_HISTORY = 'COMPANY_HISTORY' // 公司点击过的历史记录

export const utilLocalStorage = {
  // 删除localStorage
  removeLocalStorage (key:string):void {
    if (!key) return
    localStorage.removeItem(key)
  },

  // 存储localStorage
  setLocalStorage (key:string, value:any):void {
    if (!value) return
    localStorage.setItem(key, JSON.stringify(value))
  },

  // 获取 localStorage
  getLocalStorage (key:string):void {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : value
  },
  //  存公司
  setLocalStorageCompany (value: any):any {
    const list = this.getLocalStorageCompany() || []
    const index = list.findIndex((item:any) => (item.entityName === value.entityName && item.countryCode === value.countryCode))
    if (index > -1) {
      list.splice(index, 1)
      list.unshift(value)
    } else {
      list.unshift(value)
    }
    localStorage.setItem(COMPANY_HISTORY, JSON.stringify(list))
    return list
  },
  // 取公司
  getLocalStorageCompany ():any {
    const value = localStorage.getItem(COMPANY_HISTORY)
    return value ? JSON.parse(value) : undefined
  },
  // 删除公司
  removeLocalStorageCompany ():void {
    localStorage.removeItem(COMPANY_HISTORY)
  }
}

export {
  COMPANY_HISTORY
}
