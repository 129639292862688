// 格式化时间
function formatBase (date: Date | string, fmt: string) {
  if (!date) return date
  if (typeof date === 'string') {
    // 解决IOS上无法从dateStr parse 到Date类型问题
    date = date.replace(/-/g, '/')
    date = new Date(date)
  }
  const o: any = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}
// 样式时间
const formatTime = (date:any) => {
  if (!date) return
  return formatBase(new Date(date), 'yyyy-MM-dd')
}
// 样式时间
const formatTimestamp = (date:any) => {
  if (!date) return
  return formatBase(new Date(date), 'yyyy-MM-dd hh:mm:ss')
}
// 样式时间
const formatCurrentTime = () => {
  return formatBase(new Date(), 'yyyy-MM-dd')
}
const formatTimeMonthText = (date:any) => {
  const timeMap: any = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  }
  const _time = formatTime(date)
  const _timeArr = _time?.split('-') || []
  return (`${_timeArr[2]} ${timeMap[_timeArr[1]]} ${_timeArr[0]}`)
}

export {
  formatBase,
  formatTime,
  formatTimestamp,
  formatCurrentTime,
  formatTimeMonthText
}
