import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()
const copyDirectiveFunc = (el: any, item: any): void => {
  // 如果没有可复制的，不添加copy
  if (!item.value) return
  // 如果已经添加了，不再添加
  if (el.classList.contains('app-copy')) return
  el.classList.add('app-copy')
  const _span = document.createElement('span')
  _span.innerText = 'Copy'
  _span.className = 'copy-ele'
  el.append(_span)
  _span.onclick = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    try {
      await toClipboard(`${item.value}`)
      ElMessage.success('copy success! ')
    } catch (e) {
      ElMessage.success('copy failure! ')
    }
  }
}
export const directiveFunc = (app: any) => {
  // copy
  app.directive('copy', {
    updated (el: any, binding: any) { copyDirectiveFunc(el, binding) },
    // 当被绑定的元素挂载到 DOM 中时……
    mounted (el: any, item: any) { copyDirectiveFunc(el, item) }
  })
}
