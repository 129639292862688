import request from '@/utils/axios-request'

// 校验邮箱
export const apiValidEmail = (data: any) => {
  return request({
    url: '/user/valid-email',
    method: 'post',
    data
  })
}
// 注册
export const apiRegister = (data: any) => {
  return request({
    url: '/user/register',
    method: 'post',
    data
  })
}
// 登录
export const apiLogin = (data: any) => {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}
// 忘记密码
export const apiForgetPassword = (data: any) => {
  return request({
    url: '/user/forget-password',
    method: 'post',
    data
  })
}
// 获取用户
export const apiGetUser = (params?: any) => {
  return request({
    url: '/user/get-user',
    method: 'get',
    params
  })
}
// google登录url
export const apiGetGoogleLoginUrl = () => {
  return request({
    url: '/user/get-google-login-url',
    method: 'post'
  })
}
// google登录
export const apiGoogleLogin = (params: any) => {
  return request({
    url: '/user/google-login',
    method: 'get',
    params
  })
}
