const numberRegExp = new RegExp('^[0-9]*$', 'g') // eslint-disable-line
const emailRegExp = new RegExp('^[A-Za-z0-9\u4e00-\u9fa5._-]+@[a-zA-Z0-9_-]+([.]+[a-zA-Z0-9_-]+)+$', 'g') // eslint-disable-line
const phoneRegExp = new RegExp('^[1][0-9]{10}$', 'g')
const disFullRegExp = new RegExp(/^(?!(\s+$))/, 'g') // eslint-disable-line
// 8个字符，至少一个数字一个符号
const passwordRegExp = new RegExp(/(?=^.{8,}$)((?=.*\d)(?=.*\W+)).*$/, 'g') // eslint-disable-line

const testRegExp = (regExp: any, value: any) => {
  regExp.lastIndex = 0
  return regExp.test(value)
}

const execRegExp = (regExp: any, value: any) => {
  regExp.lastIndex = 0
  return regExp.exec(value)
}
export {
  numberRegExp,
  emailRegExp,
  phoneRegExp,
  disFullRegExp,
  passwordRegExp,
  testRegExp,
  execRegExp
}
